const routes = [
  // TODO 在线缴费订单
  {
    path: '/home/orderPayOnlineList', // 列表页
    name: 'serverEstateAction!list.action1',
    component: resolve => require(['@/views/application/orderPayOnline/OrderPayOnlineList'], resolve)
  },
  {
    path: '/home/orderPayOnlineForm', // 表单页
    name: 'orderPayOnlineForm',
    component: resolve => require(['@/views/application/orderPayOnline/OrderPayOnlineForm'], resolve)
  },
  // TODO 账单明细
  {
    path: '/home/billDetailsList', // 列表页
    name: 'serverEstateAction!list.action2',
    component: resolve => require(['@/views/application/billDetails/BillDetailsList'], resolve)
  },
  {
    path: '/home/billDetailsForm', // 表单页
    name: 'billDetailsForm',
    component: resolve => require(['@/views/application/billDetails/BillDetailsForm'], resolve)
  },
  {
    path: '/home/billDetailsImport', // 导入
    name: 'billDetailsImport',
    component: resolve => require(['@/views/application/billDetails/Import'], resolve)
  },

  // TODO 计费规则（临停）
  {
    path: '/home/temporaryParkCostRuleList', // 列表页
    name: 'temporaryPark1',
    component: resolve => require(['@/views/application/temporaryParkCostRule/TemporaryParkCostRuleList'], resolve)
  },
  {
    path: '/home/temporaryParkCostRuleForm', // 列表页
    name: 'temporaryParkCostRuleForm',
    component: resolve => require(['@/views/application/temporaryParkCostRule/TemporaryParkCostRuleForm'], resolve)
  },
  // TODO 收费记录（临停）
  {
    path: '/home/temporaryParkChargeRecordList', // 列表页
    name: 'temporaryPark2',
    component: resolve => require(['@/views/application/temporaryParkChargeRecord/TemporaryParkChargeRecordList'], resolve)
  },
  // TODO 装修记录
  {
    path: '/home/decorationRecordList', // 列表页
    name: 'decoration1',
    component: resolve => require(['@/views/application/decorationRecord/DecorationRecordList'], resolve)
  },
  {
    path: '/home/decorationRecordForm', // 表单页
    name: 'decorationRecordForm',
    component: resolve => require(['@/views/application/decorationRecord/DecorationRecordForm'], resolve)
  },
  // TODO 业主装修指引
  {
    path: '/home/ownerDecorationGuidanceList', // 列表页
    name: 'decoration2',
    component: resolve => require(['@/views/application/ownerDecorationGuidance/OwnerDecorationGuidanceList'], resolve)
  },
  {
    path: '/home/ownerDecorationGuidanceForm', // 表单页
    name: 'ownerDecorationGuidanceForm',
    component: resolve => require(['@/views/application/ownerDecorationGuidance/OwnerDecorationGuidanceForm'], resolve)
  },
  {
    path: '/home/onSiteInspectionList', // 列表页
    name: 'onSiteInspectionList',
    component: resolve => require(['@/views/application/onSiteInspection/onSiteInspectionList'], resolve)
  },
  {
    path: '/home/marginManagementList', // 列表页
    name: 'marginManagementList',
    component: resolve => require(['@/views/application/marginManagement/marginManagementList'], resolve)
  },
  // TODO 服务工单
  {
    path: '/home/serviceSheetList', // 列表页
    name: 'orderAction!viewList.action?orderType=01',
    component: resolve => require(['@/views/application/serviceSheet/ServiceSheetList'], resolve)
  },
  {
    path: '/home/serviceSheetForm', // 表单页
    name: 'serviceSheetForm',
    component: resolve => require(['@/views/application/serviceSheet/ServiceSheetForm'], resolve)
  },
  // TODO 工单调度
  // 工单调度-服务工单
  {
    path: '/home/orderSchedulingList', // 列表页
    name: 'orderScheduling.ServiceWorkOrder',
    component: resolve => require(['@/views/application/orderScheduling/OrderSchedulingList'], resolve)
  },
  {
    path: '/home/orderSchedulingForm', // 表单页
    name: 'orderSchedulingForm',
    component: resolve => require(['@/views/application/orderScheduling/OrderSchedulingForm'], resolve)
  },
  // 工单调度-工单评价
  {
    path: '/home/orderSchedulingEvaluateList', // 工单评价列表页
    name: 'orderScheduling.evaluate',
    component: resolve => require(['@/views/application/orderSchedulingEvaluate/OrderSchedulingEvaluateList'], resolve)
  },
  {
    path: '/home/orderSchedulingEvaluateForm', // 工单评价表单页
    name: 'orderSchedulingEvaluateForm',
    component: resolve => require(['@/views/application/orderSchedulingEvaluate/OrderSchedulingEvaluateForm'], resolve)
  },

  // 车辆管理
  {
    path: '/home/parkPackageManageList', // 停车套餐管理列表页
    name: 'parkingLotManage1',
    component: resolve => require(['@/views/application/parkManage/ParkPackageManageList'], resolve)
  },
  {
    path: '/home/parkPackageManageForm', // 停车套餐管理Form页
    name: 'parkPackageManageForm',
    component: resolve => require(['@/views/application/parkManage/ParkPackageManageForm'], resolve)
  },
  {
    path: '/home/parkOrderManageList', // 停车订单管理列表页
    name: 'parkingLotManage2',
    component: resolve => require(['@/views/application/parkManage/ParkOrderManageList'], resolve)
  },
  {
    path: '/home/parkOrderManageForm', // 停车订单管理Form页
    name: 'parkOrderManageForm',
    component: resolve => require(['@/views/application/parkManage/ParkOrderManageForm'], resolve)
  },
  {
    path: '/home/parkCardManageList', // 停车卡管理列表页
    name: 'parkingLotManage3',
    component: resolve => require(['@/views/application/parkManage/ParkCardManageList'], resolve)
  },
  {
    path: '/home/parkingCardManagerByAudit', // 停车卡审核页
    name: 'parkingCardManagerByAudit',
    component: resolve => require(['@/views/application/parkManage/parkingCardManagerByAudit'], resolve)
  },
  {
    path: '/home/parkCardManageForm', // 停车卡管理Form页
    name: 'parkCardManageForm',
    component: resolve => require(['@/views/application/parkManage/ParkCardManageForm'], resolve)
  },
  {
    path: '/home/parkReFundList', // 退款订单管理列表页
    name: 'parkReFundList',
    component: resolve => require(['@/views/application/parkManage/ParkReFundList'], resolve)
  },
  {
    path: '/home/parkReFundForm', // 退款订单管理Form页
    name: 'parkReFundForm',
    component: resolve => require(['@/views/application/parkManage/ParkReFundForm'], resolve)
  },
  // 商品分类
  {
    path: '/home/goodsCategoryList', // 商品分类一级分类列表页
    name: 'goodsCategory1',
    component: resolve => require(['@/views/application/goodsCategory/GoodsCategoryList'], resolve)
  },
  {
    path: '/home/goodsCategoryFirstList', // 商品分类一级分类列表页
    name: 'goodsCategoryFirstList',
    component: resolve => require(['@/views/application/goodsCategory/GoodsCategoryFirstList'], resolve)
  },
  {
    path: '/home/goodsCategoryForm', // 商品分类一级分类Form页
    name: 'goodsCategoryForm',
    component: resolve => require(['@/views/application/goodsCategory/GoodsCategoryForm'], resolve)
  },
  {
    path: '/home/goodsCategorySecondList', // 商品分类二级分类列表页
    name: 'goodsCategorySecondList',
    component: resolve => require(['@/views/application/goodsCategory/GoodsCategorySecondList'], resolve)
  },
  {
    path: '/home/goodsCategorySecondForm', // 商品分类二级分类Form页
    name: 'goodsCategorySecondForm',
    component: resolve => require(['@/views/application/goodsCategory/GoodsCategorySecondForm'], resolve)
  },
  {
    path: '/home/goodsCategoryThirdList', // 商品分类三级分类列表页
    name: 'goodsCategoryThirdList',
    component: resolve => require(['@/views/application/goodsCategory/GoodsCategoryThirdList'], resolve)
  },
  {
    path: '/home/goodsCategoryThirdForm', // 商品分类三级分类Form页
    name: 'goodsCategoryThirdForm',
    component: resolve => require(['@/views/application/goodsCategory/GoodsCategoryThirdForm'], resolve)
  },

  // 问卷调查
  {
    path: '/home/surveyList', // 列表页
    name: 'satisfactionRateSurvey1',
    component: resolve => require(['@/views/application/survey/SurveyList'], resolve)
  },
  {
    path: '/home/surveyForm', // 表单页
    name: 'surveyForm',
    component: resolve => require(['@/views/application/survey/SurveyForm'], resolve)
  },
  // 问卷结果
  {
    path: '/home/surveyResult',
    name: 'surveyResult',
    component: resolve => require(['@/views/application/survey/result/surveyResultForm'], resolve)
  },
  // 问卷调查分类
  {
    path: '/home/surveyCategoryList', // 列表页
    name: 'surveyCategoryList',
    component: resolve => require(['@/views/application/survey/category/categoryList'], resolve)
  },
  {
    path: '/home/surveyCategoryForm', // 详情页
    name: 'surveyCategoryForm',
    component: resolve => require(['@/views/application/survey/category/categoryForm'], resolve)
  },
  // 问卷调查参与人信息
  {
    path: '/home/surveyParticipationList', // 列表页
    name: 'surveyParticipationList',
    component: resolve => require(['@/views/application/survey/participation/participationList'], resolve)
  },
  {
    path: '/home/surveyParticipationForm', // 详情页
    name: 'surveyParticipationForm',
    component: resolve => require(['@/views/application/survey/participation/participationForm'], resolve)
  },

  {
    path: '/home/orderEvaluateList', // 工单评价列表页
    name: 'ordercomment1',
    component: resolve => require(['@/views/application/orderEvaluate/OrderEvaluateList'], resolve)
  },
  {
    path: '/home/orderEvaluateForm', // 工单评价表单页
    name: 'orderEvaluateForm',
    component: resolve => require(['@/views/application/orderEvaluate/OrderEvaluateForm'], resolve)
  },

  // 平台应用库 - 应用库
  {
    path: '/home/applicationLibraryPlatformList', // 列表页
    name: 'applicationLibraryPlatform',
    component: resolve => require(['@/views/application/applicationLibrary/applicationLibraryPlatform/ApplicationLibraryPlatformList'], resolve)
  },
  {
    path: '/home/applicationLibraryPlatformForm', // 表单页
    name: 'applicationLibraryPlatformForm',
    component: resolve => require(['@/views/application/applicationLibrary/applicationLibraryPlatform/ApplicationLibraryPlatformForm'], resolve)
  },
  {
    path: '/home/grantApplicationPlatformForm', // 设置公司授权应用
    name: 'grantApplicationPlatformForm',
    component: resolve => require(['@/views/application/applicationLibrary/applicationLibraryPlatform/GrantApplicationPlatformForm'], resolve)
  },
  {
    path: '/home/batchGrantApplicationPlatformForm', // 批量设置租户授权应用
    name: 'bacthGrantApplicationPlatformForm',
    component: resolve => require(['@/views/application/applicationLibrary/applicationLibraryPlatform/BatchGrantApplicationPlatform'], resolve)
  },
  // 平台应用库 - 版块模板
  {
    path: '/home/sectionPlatformList', // 列表页
    name: 'sectionPlatform',
    component: resolve => require(['@/views/application/applicationLibrary/sectionPlatform/SectionPlatformList'], resolve)
  },
  {
    path: '/home/sectionPlatformForm', // 表单页
    name: 'sectionPlatformForm',
    component: resolve => require(['@/views/application/applicationLibrary/sectionPlatform/SectionPlatformForm'], resolve)
  },
  // 租户应用库 - 应用库
  {
    path: '/home/applicationLibraryTenantList', // 列表页
    name: 'applicationLibraryTenant',
    component: resolve => require(['@/views/application/applicationLibrary/applicationLibraryTenant/ApplicationLibraryTenantList'], resolve)
  },
  {
    path: '/home/applicationLibraryTenantForm', // 表单页
    name: 'applicationLibraryTenantForm',
    component: resolve => require(['@/views/application/applicationLibrary/applicationLibraryTenant/ApplicationLibraryTenantForm'], resolve)
  },
  {
    path: '/home/batchApplicationTenantForm', // 批量设置项目应用
    name: 'batchApplicationTenantForm',
    component: resolve => require(['@/views/application/applicationLibrary/applicationLibraryTenant/BatchApplicationTenantForm'], resolve)
  },
  {
    path: '/home/grantApplicationTenantForm', // 设置项目授权应用
    name: 'grantApplicationTenantForm',
    component: resolve => require(['@/views/application/applicationLibrary/applicationLibraryTenant/GrantApplicationTenantForm'], resolve)
  },
  {
    path: '/home/batchProjectApplicationLibranyTenantForm', // 设置项目批量授权应用
    name: 'batchProjectApplicationLibranyTenantForm',
    component: resolve => require(['@/views/application/applicationLibrary/applicationLibraryTenant/BatchProjectApplicationLibranyTenantForm'], resolve)
  },
  {
    path: '/home/pauseTimeTenantForm', // 设置应用暂停时间
    name: 'pauseTimeTenantForm',
    component: resolve => require(['@/views/application/applicationLibrary/applicationLibraryTenant/PauseTimeTenantForm'], resolve)
  },
  {
    path: '/home/applicationLibraryTenantEditForm', // 编辑租户授权应用
    name: 'applicationLibraryTenantEditForm',
    component: resolve => require(['@/views/application/applicationLibrary/applicationLibraryTenant/ApplicationLibraryTenantEditForm'], resolve)
  },
  // 租户应用库 - 版块模板
  {
    path: '/home/sectionTenantList', // 列表页
    name: 'sectionTenant',
    component: resolve => require(['@/views/application/applicationLibrary/sectionTenant/SectionTenantList'], resolve)
  },
  {
    path: '/home/sectionTenantForm', // 表单页
    name: 'sectionTenantForm',
    component: resolve => require(['@/views/application/applicationLibrary/sectionTenant/SectionTenantForm'], resolve)
  },
  // 项目应用库
  {
    path: '/home/applicationLibraryProjectList', // 列表页
    name: 'applicationLibraryCommunity',
    component: resolve => require(['@/views/application/applicationLibrary/applicationLibraryProject/ApplicationLibraryProjectList'], resolve)
  },
  {
    path: '/home/applicationLibraryProjectForm', // 表单页
    name: 'applicationLibraryProjectForm',
    component: resolve => require(['@/views/application/applicationLibrary/applicationLibraryProject/ApplicationLibraryProjectForm'], resolve)
  },
  {
    path: '/home/applicationSortForm', // 设置应用版块排序
    name: 'applicationSortForm',
    component: resolve => require(['@/views/application/applicationLibrary/applicationLibraryProject/ApplicationSortForm'], resolve)
  },
  {
    path: '/home/pauseTimeProjectForm', // 设置应用暂停时间
    name: 'pauseTimeProjectForm',
    component: resolve => require(['@/views/application/applicationLibrary/applicationLibraryProject/PauseTimeProjectForm'], resolve)
  },
  {
    path: '/home/serviceInfoForm', // 服务信息
    name: 'serviceInfoForm',
    component: resolve => require(['@/views/application/applicationLibrary/applicationLibraryProject/ServiceInfoForm'], resolve)
  },
  {
    path: '/home/applicationLibraryProjectEditForm', // 编辑项目授权应用
    name: 'applicationLibraryProjectEditForm',
    component: resolve => require(['@/views/application/applicationLibrary/applicationLibraryProject/ApplicationLibraryProjectEditForm'], resolve)
  },


  // 一键管家
  {
    path: '/home/butlerMgrList', // 列表页
    name: 'callButler1',
    component: resolve => require(['@/views/application/butlerMgr/ButlerMgrList'], resolve)
  },
  {
    path: '/home/butlerMgrForm', // 表单页
    name: 'butlerMgrForm',
    component: resolve => require(['@/views/application/butlerMgr/ButlerMgrForm'], resolve)
  },
  {
    path: '/home/butlerMgrView', // 详情页
    name: 'butlerMgrView',
    component: resolve => require(['@/views/application/butlerMgr/ButlerMgrView'], resolve)
  },
  {
    path: '/home/carParkCouponList', // 优惠券列表
    name: 'carParkCouponList',
    component: resolve => require(['@/views/application/carParkCoupon/CarParkCouponList'], resolve)
  },
  {
    path: '/home/carParkCouponForm', // 优惠券表单
    name: 'carParkCouponForm',
    component: resolve => require(['@/views/application/carParkCoupon/CouponForm'], resolve)
  },
  {
    path: '/home/releaseRecord', // 发放记录
    name: 'releaseRecord',
    component: resolve => require(['@/views/application/carParkCoupon/ReleaseRecordList'], resolve)
  },
  // {
  //   path: '/home/attendanceSettingList', // 列表页
  //   name: 'attendanceSettingList',
  //   component: resolve => require(['@/views/application/attendanceSetting/attendanceSchedulingList'], resolve)
  // },
  // 新增 工单调度页面 工单分类页面
  {
    path: '/home/orderSchedulingClassification', // 工单分类
    name: 'OrderSchedulingClassificationList',
    component: resolve => require(['@/views/application/orderSchedulingClassification/OrderSchedulingClassificationList'], resolve)
  },
  // 新增 工单调度页面 服务设置页面 serviceSetting
  {
    path: '/home/orderSchedulingServiceSetting', // 工单分类
    name: 'orderSchedulingServiceSetting',
    component: resolve => require(['@/views/application/orderSchedulingClassification/orderSchedulingServiceSetting'], resolve)
  },

  // 拜访模板
  {
    path: '/home/VisitOwnerConfigList', // 列表页
    name: 'VisitOwnerConfigList',
    component: resolve => require(['@/views/application/visitOwnerConfig/VisitOwnerConfigList'], resolve)
  },
  {
    path: '/home/VisitOwnerConfigForm', // 表单页
    name: 'VisitOwnerConfigForm',
    component: resolve => require(['@/views/application/visitOwnerConfig/VisitOwnerConfigForm'], resolve)
  },
  // 拜访计划
  {
    path: '/home/VisitPlanConfigList', // 列表页
    name: 'VisitPlanConfigList',
    component: resolve => require(['@/views/application/visitPlanConfig/VisitPlanConfigList'], resolve)
  },
  {
    path: '/home/VisitPlanConfigForm', // 表单页
    name: 'VisitPlanConfigForm',
    component: resolve => require(['@/views/application/visitPlanConfig/VisitPlanConfigForm'], resolve)
  },
  // 拜访任务
  {
    path: '/home/VisitTaskList', // 列表页
    name: 'VisitTaskList',
    component: resolve => require(['@/views/application/visitTask/VisitTaskList'], resolve)
  },
  {
    path: '/home/VisitTaskForm', // 执行页
    name: 'VisitTaskForm',
    component: resolve => require(['@/views/application/visitTask/VisitTaskForm'], resolve)
  }
]

// attendanceSchedulingList
export default routes
